

































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { MediasGetters } from '@/store/modules/medias/enums';
import { MerchantPrivacy } from '@/models/config/merchantPrivacy';
import { CommonGetters } from '@/store/modules/common/enums';
import { configService } from '@/services/config/config.service';
import { isNullOrEmpty, isNullOrWhiteSpace } from '@glitchedmob/isnullorempty';
import { MerchantConfig } from '@/models/config/merchantConfig';
import { MerchantConfigGetters } from '@/store/modules/merchant-config/enums';

@Component({})
export default class PrivacyContent extends Vue {
  @Prop()
  public merchantCfg!: MerchantConfig;
  @Prop({ default: false })
  public isMobileAppRegistration!: boolean;
  protected loaded = false;
  public merchantPrivacy: MerchantPrivacy = new MerchantPrivacy();
  private privacyCogesContent = '';
  private privacyMerchantContent = '';

  @Watch('language')
  public async loadPrivacyText(): Promise<void> {
    this.loaded = false;
    const merchantId = this.merchantConfig.merchantId;
    if (!isNullOrWhiteSpace(merchantId)) {
      await configService
        .getMerchantPrivacy(merchantId, this.language)
        .then((response) => {
          this.merchantPrivacy = response;
        })
        .finally(() => {
          this.loaded = true;
          if (isNullOrEmpty(this.merchantPrivacy.privacy)) {
            this.privacyMerchantContent = this.getPrivacyMerchant();
          } else {
            this.privacyMerchantContent = this.merchantPrivacy.privacy;
          }
        });
    } else {
      this.loaded = true;
      this.getPrivacyCoges();
    }
  }

  async mounted(): Promise<void> {
    if (!this.loaded) this.loadPrivacyText();
    this.getPrivacyCoges();
  }

  public get language(): string {
    return this.$store.getters[CommonGetters.Language];
  }

  get hasMerchant(): boolean {
    if (this.merchantConfig) {
      return !!this.merchantConfig.merchantId;
    }
    return this.$store.getters[MediasGetters.GetMerchantId];
  }

  public get merchantConfig(): MerchantConfig {
    if (this.merchantCfg) {
      return this.merchantCfg;
    }
    return this.$store.getters[MerchantConfigGetters.GetMerchantConfig];
  }

  private getPrivacyCoges(): void {
    this.privacyCogesContent = require('@/assets/privacy/' +
      this.language +
      '/privacypolicy_coges.html');
  }

  private getPrivacyMerchant(): string {
    let template: string = require('@/assets/privacy/' +
      this.language +
      '/privacypolicy_merchantgeneric.html');
    template = template.replaceAll(
      '###legalCompanyName###',
      this.merchantConfig.legalCompanyName ?? '',
    );
    template = template.replaceAll(
      '###legalAddress###',
      this.merchantConfig.legalAddress ?? '',
    );
    template = template.replaceAll(
      '###legalVatCode###',
      this.merchantConfig.legalVatCode ?? '',
    );
    template = template.replaceAll(
      '###legalEmail###',
      this.merchantConfig.legalEmail ?? '',
    );
    return template;
  }

  private goBack(): void {
    this.$router.go(-1);
  }
}
